<template>
    <div class="check-box" @click="active==1?active=0:active=1">
        <img src="@/assets/icons/white-check.svg" v-if="active==1" alt="">
    </div>
</template>
<script> 

export default  {
    props:['value'],
    data() {
        return{
            active:0
        }    
    },
         watch: {
         value: {
             handler(after) {
                 this.active = after;
             },
             immediate: true,
         },
         active(){  
            this.$emit('input', this.active);
         }
     },
}
</script>

<style lang="scss">
.check-box{
    width: 20px;
    height: 20px;
    border: 1.5px solid #DDDFE2;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        cursor: pointer;
        filter: invert(25%) sepia(12%) saturate(499%) hue-rotate(187deg) brightness(96%) contrast(85%);
    }
     
}
 
</style>